<template>
  <div>
    <Loader v-if="loader" />
    <md-table v-else>
      <md-table-toolbar>
        <div class="md-list-item-text space-down">
          <span class="md-display-1 title-fix">Paises/Mercados</span>
          <span class="md-caption">Listado de mercados registrados</span>
        </div>
        <Create />
      </md-table-toolbar>
      <md-table-row>
        <md-table-head md-numeric>ID</md-table-head>
        <md-table-head>Código</md-table-head>
        <md-table-head>País</md-table-head>
        <md-table-head>Editar</md-table-head>
        <md-table-head>Eliminar</md-table-head>
      </md-table-row>
      <md-table-row v-if="list.length < 1">
        <Empty />
      </md-table-row>
      <md-table-row v-for="item in list" v-else>
        <md-table-cell md-numeric>{{item.id}}</md-table-cell>
        <md-table-cell>{{item.code}}</md-table-cell>
        <md-table-cell>{{item.country}}</md-table-cell>
        <md-table-cell>
          <Edit :data="item" />
        </md-table-cell>
        <md-table-cell>
          <md-button class="md-icon-button md-primary" @click="deleteCountry(item.id)">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
import Create from '@/components/Countries/Create.vue'
import Loader from '@/components/Common/Loader.vue'
import Edit from '@/components/Countries/Edit.vue'
import Empty from '@/components/Common/Empty.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Countries',
  components: {
    Edit, Create, Loader, Empty
  },
  data() {
    return {
      loader: true
    }
  },
  computed: {
    ...mapGetters({list: 'countries/listCountries'})
  },
  created() {
    this.countries().then(() => {
      this.loader = false
    })
  },
  methods: {
    ...mapActions({countries: 'countries/getCountries', deleteCountry: 'countries/deleteCountry'}),
  }
}
</script>
