import {required, maxLength, minLength, between} from 'vuelidate/lib/validators'

export default {
  country: {
    code: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(25)
    },
    country: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(25)
    }
  }
}
