<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <List class="margin-fix"/>
    </div>
  </div>
</template>
<script>
import List from '@/components/Countries/List.vue'
import auth from '@/mixins/auth'
export default {
  name: 'Countries',
  mixins: [auth],
  components: {
    List
  }
}
</script>
