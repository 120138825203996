<template>
  <div>
    <md-button class="md-icon-button" @click="showDialog = true">
      <md-icon>build</md-icon>
    </md-button>
    <md-dialog :md-active.sync="showDialog">
    <md-dialog-title>Editar mercado</md-dialog-title>
    <div class="md-layout md-alignment-center margin-fix">
      <p>Introduzca código y pais para registrar el mercado</p>
      <div class="md-layout-item md-size-80">
        <md-field>
          <label>Código de país</label>
          <md-input v-model="$v.country.code.$model" />
        </md-field>
        <div v-if="$v.country.code.$dirty">
          <span class="md-helper-text" v-if="!$v.country.code.required">Campo requerido</span>
          <span class="md-helper-text" v-if="!$v.country.code.minLength">Minimo 2 caracteres</span>
          <span class="md-helper-text" v-if="!$v.country.code.maxLength">Maximo de caracteres</span>
        </div>
      </div>
    </div>
    <div class="md-layout md-alignment-center margin-fix">
      <div class="md-layout-item md-size-80">
        <md-field>
          <label>Nombre de país</label>
          <md-input v-model="$v.country.country.$model" />
        </md-field>
        <div v-if="$v.country.country.$dirty">
          <span class="md-helper-text" v-if="!$v.country.country.required">Campo requerido</span>
          <span class="md-helper-text" v-if="!$v.country.country.minLength">Minimo 2 caracteres</span>
          <span class="md-helper-text" v-if="!$v.country.country.maxLength">Maximo de caracteres</span>
        </div>
      </div>
    </div>
    <md-dialog-actions class="margin-fix">
      <md-button class="md-primary" @click="onCancel">Cerrar</md-button>
      <md-button class="md-primary" @click="onConfirm">Editar</md-button>
    </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import validations from '@/validations/country'
import axios from 'axios'
export default {
  name: 'Edit',
  props: ['data'],
  data() {
    return {
      country: this.$props.data,
      showDialog: false,
    }
  },
  validations: validations,
  methods: {
    onConfirm () {
      if (!this.$v.$invalid) {
        this.$store.dispatch('countries/editCountry', this.country)
        this.showDialog = false
      }
    },
    onCancel () {
      this.showDialog = false
    }
  }
}
</script>
